<template>
	<div ref="devices" v-if="logged_user.customer_permission != 'SENDER'" class="router-view-wrapper">
		<div class="data-wrapper">
			<q-item tag="label">
				<q-item-section>
					<q-item-label class="text-left">Select all devices</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-toggle
						v-model="select_all_devices"
						@input="selectAllDevices()"
					/>
				</q-item-section>
			</q-item>

			<q-item style="word-break: break-all;" class="device-wrapper">
				<q-item-section>
					<b>Field name</b>
				</q-item-section>
				<q-item-section>
					<b>Channel type</b>
				</q-item-section>
				<q-item-section avatar>
					<q-toggle
						v-model="empty_value"
						style="visibility: hidden;"
					/>
				</q-item-section>
			</q-item>

			<div v-for="(device, index) in devices" :key="index">
				<Device
					:index="index"
					:device="device"
					:select_all_devices="select_all_devices"
					@update-selected-device-state="updateSelectedDeviceState"
					@show-email-subject="showEmailSubject"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Device from '@/components/message_forms/devices_tab/Device.vue';
import { mapGetters, mapActions } from 'vuex'
export default {
	name: 'MessageFormDevices',
	components:{
		Device,
	},
	data(){
		return{
			empty_value: false,
		}
	},
	computed:{
		...mapGetters([
			'scenario_type',
			'devices',
			'scenario',
			'all_devices_state',
			'sms_channel_active',
			'email_channel_active',
			'voice_channel_active',
			'sms_dispatch_content_value',
			'email_dispatch_content_value',
			'voice_dispatch_content_value',
			'errors_per_tab',
			'include_links_in_sms',
			'include_links_in_email',
			'tts_voice',
			'current_CG',
			'people',
			'logged_user'
		]),
		select_all_devices: {
			get() {
				return this.$store.getters.select_all_devices;
			},
			set(val) {
				this.updateSelectAllDevices(val);
			}
		},
		is_email_selected: {
			get() {
				return this.$store.getters.is_email_selected;
			},
			set(val) {
				this.updateIsEmailSelected(val);
			}
		}
	},
	methods:{
		...mapActions([
			'updateDevice',
			'clearDevices',
			'updateAllDevicesState',
			'updateOneDeviceState',
			'updateIsEmailSelected',
			'updateSelectAllDevices',,
			'updateSelectedDevices',
			'showEmailSubject',
			'checkForAttachmentSliders'
		]),
		getMessage() {
			const errors = this.$store.getters.errors_per_tab['Devices'];
			for (let error of errors){
				this.$q.notify({
					timeout: 6700,
					message: error,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			}
		},
		selectAllDevices(){
			let device_state = false;
			let old_devices = JSON.parse(JSON.stringify(this.scenario.devices))
			this.clearDevices();
			if(this.select_all_devices){
				if(this.scenario_type !== 'conference'){
					this.updateIsEmailSelected(true)
				}
				device_state = true;
				for(let i in this.devices){
					for (let scenario_device of old_devices){
						if (this.devices[i].label_plus_type === scenario_device.label_plus_type){
							this.devices[i].position = scenario_device.position
							break
						}
					}
					this.updateDevice(this.devices[i])
				}
			}else if(this.scenario_type !== 'conference'){
				this.updateIsEmailSelected(false)
			}
			this.updateAllDevicesState({"state": device_state, "devices_len": this.devices.length});
			this.checkForAttachmentSliders();
		},
		updateSelectedDeviceState(obj){
			this.updateOneDeviceState(obj);
			this.updateSelectedDevices();
		},
	},
	created(){
		this.showEmailSubject();
		this.getMessage()
		if (this.$store.getters.select_all_devices){
			this.selectAllDevices()
		}
	},
}
</script>
<style lang="scss" scoped>
	.device-wrapper{
		border-bottom: 1px solid $grey-4;
	}
</style>
