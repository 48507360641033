import { render, staticRenderFns } from "./Device.vue?vue&type=template&id=5d2bc800&scoped=true&"
import script from "./Device.vue?vue&type=script&lang=js&"
export * from "./Device.vue?vue&type=script&lang=js&"
import style0 from "./Device.vue?vue&type=style&index=0&id=5d2bc800&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d2bc800",
  null
  
)

export default component.exports
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QItem,QItemSection,QIcon,QItemLabel,QToggle});
