<template>
	<div class="device-wrapper">
		<q-item style="word-break: break-all;" tag="label">
			<q-item-section>
				<div class="flex-start justify-center">
					<q-icon :name='display_icon' color="dark" size='sm'/>
					{{ device.label }}
				</div>
			</q-item-section>
			<q-item-section>
				<q-item-label v-if="device.field_type == 'PHONE(VOICE)'" class="text-left">PHONE (VOICE)</q-item-label>
				<q-item-label v-else-if="device.field_type == 'PHONE(SMS)'" class="text-left">PHONE (SMS)</q-item-label>
				<q-item-label v-else class="text-left">{{device.field_type}}</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle
					v-model="device_state"
					:val="device"
					@input="updateState"
				/>
			</q-item-section>
		</q-item>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	name: 'Device',
	props:{
		device: Object,
		index: Number,
		select_all_devices: Boolean
	},
	data(){
		return{
			device_state: false
		}
	},
	computed:{
		...mapGetters(['all_devices_state']),
		display_icon(){
			if(this.device.field_type === 'EMAIL'){
				return 'o_email'
			}else if(this.device.field_type === 'PHONE(VOICE)'){
				return 'o_call';
			}else if(this.device.field_type === 'PHONE(SMS)'){
				return 'o_textsms';
			}
		}
	},
	methods:{
		...mapActions(['updateDevice', 'removeDevice', 'updateOneDeviceState']),
		updateState(){
			if(this.device_state){
				this.updateDevice(this.device);
			}else{
				this.removeDevice(this.device);
			}
			this.$emit('update-selected-device-state', {"index": this.index, "state": this.device_state});
			this.$emit('show-email-subject');
		}
	},
	watch:{
		all_devices_state:{
			deep: true,
			handler(){
				if(this.all_devices_state[this.index] != this.device_state){
					this.device_state = this.all_devices_state[this.index];
				}
			}
		},
	},
	created(){
		this.device_state = this.all_devices_state[this.index];
		if (typeof(this.device_state) == 'undefined' && this.select_all_devices) {
			this.device_state = true
			let obj = {'index': this.index, 'state': this.device_state}
			this.updateOneDeviceState(obj);
			this.updateState()
		}else if(!this.device_state){
			this.device_state = false
			let obj = {'index': this.index, 'state': this.device_state}
			this.updateOneDeviceState(obj);
			this.updateState()
		}
	}
}
</script>
<style lang="scss" scoped>
	.device-wrapper{
		border-bottom: 1px solid $grey-4;
	}
</style>